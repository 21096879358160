import { Form, Input, Select } from 'antd';
import React from 'react';

function PersonalInformation() {
  return (
    <div>
      <div className="flex flex-col md:flex-row gap-4 justify-between">
        <Form.Item
          name="first_name"
          className="md:w-1/2 "
          label={<p>First Name</p>}
          rules={[
            {
              required: true,
              message: "Enter your first name!",
            },
          ]}
        >
          <Input
            className="h-[56px] focus:border-[#4F2915] focus:shadow-none"
            placeholder="First Name"
          />
        </Form.Item>
        <Form.Item
          name="last_name"
          className="md:w-1/2"
          label={<p>Last Name</p>}
          rules={[
            {
              required: true,
              message: "Enter your last name!",
            },
          ]}
        >
          <Input
            className="h-[56px] focus:border-[#4F2915] focus:shadow-none"
            placeholder="Last Name"
          />
        </Form.Item>
      </div>
      <div className="flex flex-col md:flex-row gap-4 justify-between">
        <Form.Item  name="email" rules={[
            {
              required: true,
              message: "Enter your email!",
            },
          ]} className="md:w-1/2 " label={<p>Email</p>}>
          <Input
            className="h-[56px] focus:border-[#4F2915] focus:shadow-none"
            placeholder="jondoe@gmail.com"
          />
        </Form.Item>
        <Form.Item rules={[
            {
              required: true,
              message: "Enter your country!",
            },
          ]} name="country" className="md:w-1/2 " label={<p>Country</p>}>
          <Select placeholder="Country" className="h-[56px]">
            <Select.Option value="Togo">Togo</Select.Option>
            <Select.Option value="Benin Republic">Benin Republic</Select.Option>
            <Select.Option value="Ghana">Ghana</Select.Option>
            <Select.Option value="Senegal">Senegal</Select.Option>
            <Select.Option value="Côte d’Ivoire">Côte d’Ivoire</Select.Option>
            <Select.Option value="Nigeria">Nigeria</Select.Option>
            <Select.Option value="Burkina Faso">Burkina Faso</Select.Option>
            <Select.Option value="Cameroun">Cameroun</Select.Option>
            <Select.Option value="Liberia">Liberia</Select.Option>
            <Select.Option value="Others">Others</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className="flex gap-4 flex-col md:flex-row justify-between">
        <Form.Item
          name="role"
          className="md:w-1/2"
          rules={[
            {
              required: true,
              message: "Enter your role!",
            },
          ]}
          label={<p>Role in AIESEC</p>}
        >
          <Select placeholder="Role" className="h-[56px] ">
            <Select.Option value="Alumni">Alumni</Select.Option>
            <Select.Option value="EST">EST</Select.Option>
            <Select.Option value="LCP">LCP</Select.Option>
            <Select.Option value="LCVP">LCVP</Select.Option>
            <Select.Option value="MCP">MCP</Select.Option>
            <Select.Option value="MCVP">MCVP</Select.Option>
            <Select.Option value="TM">TM</Select.Option>
            <Select.Option value="TL">TL</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item  name="language" className="md:w-1/2" label={<p>Language</p>}>
          <Select placeholder="Language" className="h-[56px]">
            <Select.Option value="French">French</Select.Option>
            <Select.Option value="English">English</Select.Option>
          </Select>
        </Form.Item>
      </div>
    </div>
  );
}

export default PersonalInformation;
